import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { reduce, get, isNumber, isString } from 'lodash';
export const GET_CITIES = gql `
  query getCitiesByCountryCode($countryID: Float, $countryCode: String) {
    cities(countryID: $countryID, countryCode: $countryCode) {
      cities {
        id
        countryCode
        code
        name
        latitude
        longitude
        timeZone
      }
    }
  }
`;
export const useCities = (countryID, countryCode) => {
    const { loading, error, data } = useQuery(GET_CITIES, {
        variables: {
            countryID: isNumber(countryID) ? countryID : 0,
            countryCode: isString(countryCode) ? countryCode : '',
        },
    });
    const cityMap = useMemo(() => {
        var _a;
        if (loading || error)
            return {};
        return reduce((_a = data === null || data === void 0 ? void 0 : data.cities) === null || _a === void 0 ? void 0 : _a.cities, (acc, city) => (Object.assign(Object.assign({}, acc), { [city.id]: city })), {});
    }, [data, loading, error]);
    return {
        loading,
        error: !!error,
        data,
        getCity: (id) => get(cityMap, id || ''),
    };
};
export default useCities;
