var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { map, isEmpty, includes, toLower } from 'lodash';
import { Select } from 'antd';
import useCities from '@helpers/useCities';
const CityInput = forwardRef((props, ref) => {
    var _a;
    const { className, countryCode, value, onSelect, onChange, allowClear, placeholder, disabled } = props, otherProps = __rest(props, ["className", "countryCode", "value", "onSelect", "onChange", "allowClear", "placeholder", "disabled"]);
    const { loading, error, data } = useCities(undefined, countryCode);
    return (React.createElement(Select, Object.assign({ ref: ref, className: className, placeholder: placeholder, defaultActiveFirstOption: false, showSearch: true, allowClear: !!allowClear, disabled: error || loading || disabled || isEmpty(data), onChange: onChange, onSelect: onSelect, value: value, filterOption: (input, option) => includes(toLower(option.props.title), toLower(input)) ||
            option.props.value == input }, otherProps), map((_a = data === null || data === void 0 ? void 0 : data.cities) === null || _a === void 0 ? void 0 : _a.cities, ({ id, name }) => (React.createElement(Select.Option, { key: id, value: id, title: name }, name)))));
});
CityInput.displayName = 'CityInput';
export default CityInput;
